<script setup>
import { transl, useTranslation } from "@/composables/useTranslation";

const route = useRoute();
const props = defineProps(["dialog"]);
const emit = defineEmits(["update:dialog"]);

const dialog = computed({
 get: () => props.dialog,
 set: (val) => emit("update:dialog", val),
});

function close() {
 dialog.value = false;
}

function payDialog() {
 emitGlobal("pay-dialog");
}
</script>
<template>
 <v-dialog v-model="dialog" left-sheet>
  <div class="login-dialog-el ma-4">
   <div class="close" @click="close()">
    <img src="/icons/close-icon.svg" alt="" />
   </div>

   <!-- <nav class="menu-mobile">
    <ul>
     <li><a href="/" :class="{ active: route.name == 'page' }">Главная</a></li>
     <li>
      <a href="/courses" :class="{ active: route.name == 'page-courses' }">Курсы</a>
     </li>
     <li><a href="/about" :class="{ active: route.name == 'page-about' }">О нас</a></li>
     <li><span @click="payDialog()" class="pay">Поддержать</span></li>
     <li>
      <a href="/contacts" :class="{ active: route.name == 'page-contacts' }">Контакты</a>
     </li>
    </ul>
   </nav> -->
   <nav class="menu-mobile">
    <ul class="menu-items">
     <router-link to="/"
      ><li class="navigation-item px-4 active" @click="close()">
       {{ transl("Texts:Home") }}
      </li></router-link
     >

     <router-link to="/#courses"
      ><li class="navigation-item px-4" @click="close()">
       {{ transl("Texts:Courses") }}
      </li></router-link
     >

     <router-link to="/#about"
      ><li class="navigation-item px-4" @click="close()">
       {{ transl("Texts:About us") }}
      </li></router-link
     >

     <router-link to="/#contacts"
      ><li class="navigation-item px-4" @click="close()">
       {{ transl("Texts:Contacts") }}
      </li></router-link
     >
    </ul>
   </nav>
   <v-select-language
    class="ml-5 language"
    @change="(el) => setLocale(el)"
   ></v-select-language
   ><br />
   <a href="https://bibleawr.com/login">
    <div class="more-button mt-5 ml-5">
     {{ transl("Landing:Login as teacher") }}
    </div>
   </a>
  </div>
 </v-dialog>
</template>
<style scoped>
.more-button {
 color: #fff;
 display: inline-flex;
 align-items: center;
 text-transform: uppercase;
 cursor: pointer;

 padding: 19px 37px;
 background: #b1b1b1;
 border-radius: 50px;
 max-width: fit-content;
 font-size: 13px;
 font-weight: 500;
}
@media (hover: hover) {
 .more-button:hover {
  opacity: 0.8;
 }
}
@media (hover: none) {
 .more-button:active {
  opacity: 0.8;
 }
}

.menu-mobile {
 margin-top: 70px;
 min-width: 300px;
}
.menu-mobile a {
 text-decoration: none;
 color: #202324;
}
.menu-mobile ul {
 padding: 0;
 padding-left: 20px;
}
.menu-mobile ul li {
 list-style-type: none;
 padding: 0;
 margin: 0;
 padding: 15px 20px;
}

.menu-mobile ul li:hover {
 background-color: #eaeaea;
}
.menu-mobile ul li a {
 text-decoration: none;
 color: #595959;
 font-size: 18px;
 line-height: 29px;
 font-weight: 500;
 letter-spacing: 0.26px;
 max-width: 100%;
 text-overflow: ellipsis;
 overflow: hidden;
 white-space: nowrap;
 position: relative;
}

.close {
 position: absolute;
 left: 8px;
 width: 40px;
 height: 40px;
 display: flex;
 justify-content: center;
 align-items: center;
 top: 8px;
}
.close:hover img {
 width: 34px;
 height: 34px;
 cursor: pointer;
 transition: 0.1s;
}
.close img {
 width: 30px;
 height: 30px;
}
 .language {
  display: none;
 }
@media (max-width: 720px) {
 .language {
  display: block;
 }
 .menu-mobile{
    margin-bottom: 20px;
 }
}
</style>
~/composables/useTranslation